import React from 'react';
import ReactDOM from 'react-dom/client';
import { Navigate } from 'react-router-dom';

// Main APP
import App from 'nexusml-ui/src/App';

// Providers
import { AppProviders } from 'nexusml-ui/src/Providers/AppProviders';

// Views
import UserSettings from 'nexusml-ui/src/Pages/Settings/UserSettings';
import OrganizationSettings from 'nexusml-ui/src/Pages/Settings/OrganizationSettings';
import Subscription from './Pages/Settings/Subscription';
import Billing from './Pages/Settings/Billing';
import Usage from './Pages/Settings/Usage';
import EdgeDeployment from './Pages/Settings/EdgeDeployment';

// Images
import LOGO_WITH_NAME from './assets/LOGO_WITH_NAME.svg';
import LOGO from './assets/LOGO.png';

const root = ReactDOM.createRoot(document.getElementById('root'));

const appConfig = {
	appName: 'ENAIA UI',
	appLogoWithName: LOGO_WITH_NAME,
	appLogo: LOGO,
};

const authConfig = {
	domain: process.env.NEXUSML_UI_AUTH0_DOMAIN,
	clientId: process.env.NEXUSML_UI_AUTH0_CLIENT_ID,
	audience: process.env.NEXUSML_UI_AUTH0_AUDIENCE,
	scope: process.env.NEXUSML_UI_AUTH0_SCOPE,
};

root.render(
	<AppProviders appConfig={appConfig} authConfig={authConfig}>
		<App
			routerConfig={[
				{
					path: '/settings',
					children: [
						{ path: '', element: <Navigate to="/" /> },
						{ path: 'user', element: <UserSettings /> },
						{
							path: 'organization',
							element: (
								<OrganizationSettings
									customTabs={[
										// {
										// 	index: 1,
										// 	label: 'Usage',
										// 	linkTo: 'usage',
										// 	component: <Usage />,
										// },
										// {
										// 	index: 2,
										// 	label: 'Subscription',
										// 	linkTo: 'subscription',
										// 	component: <Subscription />,
										// },
										// {
										// 	index: 3,
										// 	label: 'Billing',
										// 	linkTo: 'billing',
										// 	component: <Billing />,
										// },
										{
                                            index: 6,
                                            label: 'Deploy to Edge',
                                            linkTo: 'Deploy to Edge',
                                            component: <EdgeDeployment />,
                                        },
									]}
								/>
							),
						},
					],
				},
			]}
		/>
	</AppProviders>
);
