import React, { useState } from 'react';
import { colors } from 'nexusml-ui/src/consts/colors';
import edgeDeploymentImage from '../../../assets/edge-deployment-svg.svg'; // Adjust the path as needed

const EdgeDeployment = () => {
    const [isHovered, setIsHovered] = useState(false);
  
    const handleMouseEnter = () => setIsHovered(true);
    const handleMouseLeave = () => setIsHovered(false);
  
    const handleDownloadClick = () => {
      alert('Deployment started! Downloading AI tool...');
    };
  
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '50vh',
          textAlign: 'center',
          padding: '20px',
          backgroundColor: '#f9f9f9',
        }}
      >
  
        {/* Button Section */}
        <button
          type="button"
          onClick={handleDownloadClick}
          // onMouseEnter={handleMouseEnter}
          // onMouseLeave={handleMouseLeave}
          onFocus={handleMouseEnter} // Accessibility for keyboard users
          onBlur={handleMouseLeave} // Accessibility for keyboard users
          style={{
            border: 'none',
            background: 'none',
            cursor: 'pointer',
            padding: 0,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <img
            src={edgeDeploymentImage}
            alt="Deploy to Edge"
            style={{
              width: '200px',
              height: 'auto',
              marginBottom: '10px',
              transform: isHovered ? 'scale(1.1)' : 'scale(1)',
              transition: 'transform 0.2s ease',
            }}
          />
          <span
            style={{
              fontSize: '1rem',
              fontWeight: 'bold',
              color: colors.blue,
              textDecoration: 'underline',
              transition: 'color 0.2s ease',
            }}
          >
            Download Docker Image
          </span>
        </button>

      </div>
    );
  };

export default EdgeDeployment;